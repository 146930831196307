import React, { useEffect, useState } from 'react'
import Quiz from './Quiz';
import { toast } from 'react-toastify';
// import wheelpage from './wheelpage';
// import Wheel from "spin-wheel"
// import WheelComponent from 'react-wheel-of-prizes'
// import 'react-wheel-of-prizes/dist/index.css';
// import { ReactSpinner } from 'react-spinning-wheel';
// import 'react-spinning-wheel/dist/style.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AvForm, AvField, AvCheckbox, AvInput, AvCheckboxGroup } from 'availity-reactstrap-validation';
import { get, post } from '../helpers/api_helper';

import 'swiper/css';
import 'swiper/css/effect-fade';

import { EffectFade, Autoplay } from 'swiper/modules';
import Loader from '../component/Loader';
import { useNavigate } from 'react-router-dom';

export default function Home() {

    const navigate = useNavigate();

    const [banners, setBanners] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [data, setData] = useState([]);
    const [setpForm, setStepForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [user, setUser] = useState(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [reportShow, setReportShow] = useState(false);

    useEffect(() => {
        let user = localStorage.getItem('user');
        if (!user) {
            navigate('/')
        }
        getData();
    }, []);

    const getData = () => {
        if (!loading) {
            setLoading(true)
            get("home")
                .then(res => {
                    setLoading(false)
                    if (res?.statusCode == 200) {
                        setBanners(res?.data?.banners);
                        setQuestions(res?.data?.questions);
                        setData(res?.data);
                        setSubmitted(false);
                    } else {
                        toast.error(res?.error);
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.log("error while getting home data", err);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleValidSubmit = (e, v) => {
        setLoading(true);
        post("register", v)
            .then(res => {
                setLoading(false);
                if (res?.statusCode == 200) {
                    setStepForm(true);
                    setUser(res?.data);
                    setSubmitted(false);
                } else {
                    toast.error(res?.error);
                }
            })
            .catch(err => {
                setLoading(false);
                toast.error("Something Went Wrong!");
            })
    }

    // console.log('currentStep', currentStep)

    return (
        <>
            {/* <!--Container--> */}
            <div class="pad-zero">

                {
                    loading && <Loader />
                }

                {/* <!--Splitlayout --> */}
                <div id="splitlayout" class="splitlayout reset-layout">

                    {/* <!--Intro--> */}
                    <div class="intro">

                        {/* <!--Left Content Intro--> */}
                        <div class="side side-left">

                            {/* <!--Slider--> */}
                            <div class="full-height full_height" >
                                {/* <img className='banner_img' src={require('../assets/img/home-img.jpg')} /> */}
                                {/* {
                                    !setpForm ? <img className='banner_img' src={require('../assets/img/home-img.jpg')} /> : <img className='banner_img' src={questions[currentStep]?.banner} />
                                } */}

                                <img className='banner_img' src={questions[currentStep]?.banner} />

                                {/* <Swiper
                                    spaceBetween={30}
                                    effect={'fade'}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    modules={[EffectFade, Autoplay]}
                                    className="mySwiper banner_img_slider"
                                >
                                    {setpForm ?
                                        <>
                                            <SwiperSlide>
                                                <img src={questions[currentStep]?.banner} />
                                            </SwiperSlide>
                                        </>
                                        :
                                        <>
                                            {banners?.map((item, index) => {
                                                return (
                                                    <SwiperSlide>
                                                        <img src={item?.banner} />
                                                       
                                                    </SwiperSlide>
                                                )
                                            })
                                            }
                                        </>
                                    }
                                </Swiper> */}
                            </div>
                            {/* <!--/Slider--> */}

                            <div class="col-xs-12 align-center content-wrap content_wrap">

                                {/* <!--Logo--> */}
                                <header class="logo text-left">
                                    <img src={require('../assets/img/sugar.png')} alt="logo" />
                                </header>
                                {/* <!--/Logo--> */}

                            </div>
                        </div>
                        {/* <!--/Left Content Intro--> */}

                        {/* <!--Right Content Intro--> */}
                        <div class="side side-right">

                            {/* <!--Right Nav--> */}
                            {/* <div class="call-to-action contact">
                                <div>
                                    <span class="goto-next">
                                        more info
                                        <span class="add-sep"></span>
                                    </span>
                                    <span class="goto-close opacity-hide">
                                        close X
                                        <span class="add-sep"></span>
                                    </span>
                                </div>
                            </div> */}
                            {/* <!--/Right Nav--> */}

                            <div class="col-xs-12 align-center sec-pad-left sec-pad-right sec-pad-right-two sec-pad-top padding-top-sm-40 padding-top-xs-100 padding-top-xs-50 main-content">

                                {/* <!--main content--> */}
                                <section class="intro-text right_section_page">

                                    <div class="clearfix"></div>

                                    {/* <!--Notify Sec--> */}
                                    {/* {setpForm || submitted ? */}
                                    <section class="notify-cmpt notify-form-type-2 margin-top-xs-20 step_2_form">
                                        <div class="notify-wrap ">
                                            {/* {
                                                currentStep === questions?.length ? null : <div className='question_header'>
                                                    {
                                                        reportShow ? null : <>
                                                          
                                                            <div className='total_question'>
                                                                {currentStep + 1}/{questions?.length}
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            } */}
                                            <Quiz
                                                setReportShow={setReportShow}
                                                questions={questions}
                                                data={data}
                                                setCurrentStep={setCurrentStep}
                                                setLoading={setLoading}
                                                user={user}
                                                setStepForm={setStepForm}
                                                submitted={submitted}
                                                setSubmitted={setSubmitted}
                                            />

                                        </div>
                                    </section>
                                    {/* : */}

                                    {/* } */}

                                    {/* <!--/Notify Sec--> */}

                                    {/* <!--Twitter Sec--> */}

                                    {/* <!--/Twitter Sec--> */}

                                    <div class="clearfix"></div>
                                </section>
                                {/* <!--/main content--> */}

                            </div>

                            <div class="container-fluid pad-zero social-wrpper">

                                {/* <!--Footer--> */}
                                <footer class="copyright-text margin-sm-30 margin-top-xs-0">
                                    {/* <!--Social Icons--> */}
                                    <ul class="social-icons animated">
                                        <li>
                                            <a href="https://www.facebook.com/SugarfreeIndia/" target='_blank' class="social-icon">
                                                <span class="fa" data-hover="&#xf09a;">&#xf09a;</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://x.com/i/flow/login?redirect_after_login=%2Fsugarfreeindia" target='_blank' class="social-icon">
                                                <span class="fa" data-hover="&#xf099;">&#xf099;</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UCpUmPO6BUHEawp3VLggNTAw" target='_blank' class="social-icon">
                                                <span class="fa" data-hover="&#xf167;">&#xf167;</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/sugarfree.india/" target='_blank' class="social-icon">
                                                <span class="fa" data-hover="&#xf16d;">&#xf16d;</span>
                                            </a>
                                        </li>
                                    </ul>
                                    {/* <!--/Social Icons--> */}

                                    <div className='footer_copyright'>

                                        <ul className='footer_link'>
                                            <li>Copyright © Sugar Free.</li>
                                            <li><a href='https://www.sugarfree-india.com/contest-and-promotions' target='_blank'>Terms</a></li>
                                            <li>|</li>
                                            <li><a href='https://www.zyduswellness.com/privacy-policy.php' target='_blank'>Privacy Policy</a></li>
                                        </ul>
                                    </div>
                                </footer>
                                {/* <!--/Footer--> */}

                            </div>


                        </div>
                        {/* <!--/Right Content Intro--> */}

                    </div>
                    {/* <!--/Intro--> */}

                    {/* <!--Right Section--> */}
                    <div class="page page-right" style={{ display: 'none' }}>
                        <div class="page-inner full-height">


                            <section>

                                {/* <!--About Sec--> */}
                                <section class="container-fluid align-center text-left animated sec-pad-top about-sec">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h3>About</h3>
                                            <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris.</p>
                                            <div class="about-icon-wrap padding-top-sm">
                                                <div class="row">
                                                    <div class="col-sm-4  col-xs-12  margin-btm-lg">
                                                        <span class="icon icon-hourglass"></span>
                                                        <span class="key-fact">
                                                            solution
                                                        </span>
                                                        <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem consequat ipsum.</p>
                                                    </div>
                                                    <div class="col-sm-4  col-xs-12 margin-btm-lg">
                                                        <span class="icon icon-basket"></span>
                                                        <span class="key-fact">
                                                            investment
                                                        </span>
                                                        <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem consequat ipsum.</p>
                                                    </div>
                                                    <div class="col-sm-4  col-xs-12 margin-btm-lg margin-bottom-0">
                                                        <span class="icon icon-bargraph"></span>
                                                        <span class="key-fact">
                                                            funding
                                                        </span>
                                                        <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem consequat ipsum.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/* <!--/About Sec--> */}

                                {/* <!--Projects Sec--> */}
                                <section class="container-fluid align-center text-left animated sec-pad-top-sm">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h3>The Project</h3>
                                            <p>Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit.</p>

                                        </div>
                                    </div>
                                    <div class="project-gal-wrap">
                                        <div class="project-gallery row" itemscope itemtype="https://schema.org/ImageGallery">
                                            <figure class="col-sm-6 margin-btm-lg margin-btm-xs" itemprop="associatedMedia" itemscope itemtype="https://schema.org/ImageObject">
                                                <a href="img/project1.jpg" itemprop="contentUrl" data-size="730x490">
                                                    <img class="img-responsive" src="img/project1.jpg" itemprop="thumbnail" alt="Image description" />
                                                    <div class="hover"><span>+</span></div>
                                                </a>
                                                <figcaption itemprop="caption description">SEO Towers</figcaption>

                                            </figure>
                                            <figure class="col-sm-6 margin-btm-lg margin-btm-xs" itemprop="associatedMedia" itemscope itemtype="https://schema.org/ImageObject">
                                                <a href="img/project2.jpg" itemprop="contentUrl" data-size="730x490">
                                                    <img class="img-responsive" src="img/project2.jpg" itemprop="thumbnail" alt="Image description" />
                                                    <div class="hover"><span>+</span></div>
                                                </a>
                                                <figcaption itemprop="caption description">Skylark</figcaption>
                                            </figure>
                                            <figure class="col-sm-6 margin-btm-xs" itemprop="associatedMedia" itemscope itemtype="https://schema.org/ImageObject">
                                                <a href="img/project3.jpg" itemprop="contentUrl" data-size="730x490">
                                                    <img class="img-responsive" src="img/project3.jpg" itemprop="thumbnail" alt="Image description" />
                                                    <div class="hover"><span>+</span></div>
                                                </a>
                                                <figcaption itemprop="caption description">Twin Towers</figcaption>
                                            </figure>
                                            <figure class="col-sm-6" itemprop="associatedMedia" itemscope itemtype="https://schema.org/ImageObject">
                                                <a href="img/project4.jpg" itemprop="contentUrl" data-size="730x490">
                                                    <img class="img-responsive" src="img/project4.jpg" itemprop="thumbnail" alt="Image description" />
                                                    <div class="hover"><span>+</span></div>
                                                </a>
                                                <figcaption itemprop="caption description">Mountainairs
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                </section>
                                {/* <!--/Projects Sec--> */}

                                {/* <!--Team Sec--> */}
                                <section class="container-fluid align-center text-left animated sec-pad-top team-sec">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h3>Our Wonderful Team</h3>
                                            <div class="row">
                                                <div class="col-sm-4  col-xs-12 margin-btm-lg">
                                                    <img class="img-responsive" src="img/team1.jpg" alt="team" />
                                                    <span class="team-per">john doe</span>
                                                    <span class="team-desn">executive</span>
                                                    <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem consequat ipsum.</p>
                                                    <ul class="team-social">
                                                        <li><a href="" target="_blank"><i class="fa fa-facebook"></i></a></li>
                                                        <li><a href="" target="_blank"><i class="fa fa-twitter"></i></a></li>
                                                        <li><a href="" target="_blank"><i class="fa fa-dribbble"></i></a></li>
                                                        <li><a href="" target="_blank"><i class="fa fa-behance"></i></a></li>
                                                    </ul>
                                                </div>
                                                <div class="col-sm-4  col-xs-12 margin-btm-lg">
                                                    <img class="img-responsive" src="img/team2.jpg" alt="team" />
                                                    <span class="team-per">max tailor</span>
                                                    <span class="team-desn">director</span>
                                                    <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem consequat ipsum.</p>
                                                    <ul class="team-social">
                                                        <li><a href="" target="_blank"><i class="fa fa-facebook"></i></a></li>
                                                        <li><a href="" target="_blank"><i class="fa fa-twitter"></i></a></li>
                                                        <li><a href="" target="_blank"><i class="fa fa-dribbble"></i></a></li>
                                                        <li><a href="" target="_blank"><i class="fa fa-behance"></i></a></li>
                                                    </ul>
                                                </div>
                                                <div class="col-sm-4  col-xs-12 margin-btm-lg margin-bottom-0">
                                                    <img class="img-responsive" src="img/team3.jpg" alt="team" />
                                                    <span class="team-per">amanda grey</span>
                                                    <span class="team-desn">creative head</span>
                                                    <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem consequat ipsum.</p>
                                                    <ul class="team-social">
                                                        <li><a href="" target="_blank"><i class="fa fa-facebook"></i></a></li>
                                                        <li><a href="" target="_blank"><i class="fa fa-twitter"></i></a></li>
                                                        <li><a href="" target="_blank"><i class="fa fa-dribbble"></i></a></li>
                                                        <li><a href="" target="_blank"><i class="fa fa-behance"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/* <!--/Team Sec--> */}

                                {/* <!--Client Sec--> */}
                                <section class="client-sec container-fluid align-center text-left animated sec-pad-top-sm">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h3>Our Clients</h3>
                                            <div class="row">
                                                <div class="col-sm-3 col-xs-6">
                                                    <img class="img-responsive" src="img/client1.png" alt="client" />
                                                </div>
                                                <div class="col-sm-3 col-xs-6">
                                                    <img class="img-responsive" src="img/client2.png" alt="client" />
                                                </div>
                                                <div class="col-sm-3 col-xs-6">
                                                    <img class="img-responsive" src="img/client3.png" alt="client" />
                                                </div>
                                                <div class="col-sm-3 col-xs-6">
                                                    <img class="img-responsive" src="img/client4.png" alt="client" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/* <!--/Client Sec--> */}

                                {/* <!--form sec--> */}
                                <section data-ng-controller="ContactController" class="container-fluid align-center animated  sec-pad">
                                    <h3>Hey <span data-ng-bind="formData.inputName||'There'">There</span>, happy to hear from you.</h3>
                                    <div>
                                        <form data-ng-submit="submit(contactform, $event)" name="contactform" method="post" class="row form-horizontal" role="form">
                                            <div class="form-group input--hoshi col-sm-6" data-ng-class="{ 'has-error': contactform.inputName.$invalid && submitted }">
                                                <div class="input-wrap">
                                                    <input autocomplete="off" data-ng-model="formData.inputName" type="text" class="form-control input__field input input__field--hoshi" id="inputName" name="inputName" placeholder="Name" required />
                                                    <label class="input__label input__label input__label--hoshi input__label--hoshi-color-1 input__label--hoshi input__label--hoshi-color-1" ></label>
                                                </div>
                                            </div>
                                            <div class="form-group  input--hoshi col-sm-6" data-ng-class="{ 'has-error': contactform.inputEmail.$invalid && submitted }">
                                                <div class="input-wrap">
                                                    <input autocomplete="off" data-ng-model="formData.inputEmail" type="email" class="form-control input input__field input__field--hoshi" placeholder="Email" id="inputEmail" name="inputEmail" required />
                                                    <label class="input__label input__label--hoshi input__label--hoshi-color-1"></label>
                                                </div>
                                            </div>
                                            <div class="form-group  input--hoshi col-sm-12" data-ng-class="{ 'has-error': contactform.inputMessage.$invalid && submitted }">
                                                <div class="input-wrap">
                                                    <textarea data-ng-model="formData.inputMessage" class="form-control input input__field input__field--hoshi" rows="4" id="inputMessage" name="inputMessage" placeholder="message" required></textarea>
                                                    <label class="input__label input__label--hoshi input__label--hoshi-color-1"></label>
                                                </div>
                                            </div>
                                            <div class="form-group col-sm-12">
                                                <div class="align-center">
                                                    <button type="submit" class="btn btn-default" data-ng-disabled="submitButtonDisabled">
                                                        <span class="mask"></span>
                                                        send
                                                    </button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>

                                    <div class="row">
                                        <div class="address-detail">
                                            <div class="col-sm-4 margin-btm-sm">
                                                <div class="sep-wrap">
                                                    <span class="add-sep"></span>
                                                </div>
                                                <span>visit</span>
                                                <p>350 Fifth Avenue, New York 10118.</p>
                                            </div>
                                            <div class="col-sm-4 margin-btm-sm">
                                                <div class="sep-wrap">
                                                    <span class="add-sep"></span>
                                                </div>
                                                <span>reach</span>
                                                <a href="mailto:hello@theme-nerds.com" target="_blank">inquiry@beavis.com</a>
                                            </div>

                                            <div class="col-sm-4 margin-btm-sm">
                                                <div class="sep-wrap">
                                                    <span class="add-sep"></span>
                                                </div>
                                                <span>call</span>
                                                <a href="callto:954788315970" target="_blank">+95 (478) 831-5970</a>
                                            </div>

                                        </div>
                                    </div>
                                </section>
                                {/* <!--/form sec--> */}

                                {/* <!--google map--> */}
                                <section class="map-sec">
                                    <div id="map_canvas"></div>
                                </section>
                                {/* <!--/google map--> */}

                            </section>
                            {/* <!--/Contact Sec--> */}

                        </div>
                    </div>
                    {/* <!--/Right Section--> */}

                </div>
                {/* <!-- /Splitlayout --> */}

            </div>
            {/* <!-- /Container --> */}
        </>
    )
}

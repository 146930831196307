import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { AvForm, AvField, AvCheckbox, AvInput, AvCheckboxGroup } from 'availity-reactstrap-validation';
import { get, post } from '../helpers/api_helper';
import { toast } from 'react-toastify';
import Popup from '../component/Popup';
export default function Main() {

    const navigate = useNavigate();

    const [banners, setBanners] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [setpForm, setStepForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [user, setUser] = useState(undefined);
    const [userData, setUserData] = useState(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [reportShow, setReportShow] = useState(false);
    const [value, setValue] = useState('');
    const [verifyBtn, setVerifyBtn] = useState(false)
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isSdkLoaded, setIsSdkLoaded] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [sdkLoaded, setSdkLoaded] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpSectionVisible, setOtpSectionVisible] = useState(false);
    const [isVerify, setIsVerify] = useState(false);
    const [verifiedPhone, setVerifiedPhone] = useState(undefined)
    const [otpV, setOtpV] = useState('');
    useEffect(() => {
        const verifiedPhone = localStorage.getItem('verifiedPhoneNumber');
        let userD = localStorage.getItem('user');
        userD = JSON.parse(userD)
        if (userD) {
            setUserData(userD)
        }
        if (verifiedPhone === value) {
            setIsVerify(true)
        } else {
            setIsVerify(false)
        }
    }, [value])

    const handleOpenPopup = () => {
        // Ensure that OTPlessSignin is available before calling initiate
        // if (!window.OTPlessSignin) {
        //     console.error("OTPlessSignin is not initialized");
        //     return;
        // }
        // const verifiedPhone = localStorage.getItem('verifiedPhoneNumber');

        // Check if the phone number has already been verified
        // if (verifiedPhone === value) {
        //     toast.error("This phone number is already verified. Please enter a different number.");
        //     return;
        // }


        // if (value === userData?.mobile) {
        //     toast.error("This phone number is already verified. Please enter a different number.");
        //     return;
        // }

        let body = {
            mobile: value
        }
        post("check_mobile", body)
            .then(res => {

                if (res?.statusCode == 200) {
                    toast.success('This phone number is already verified. Please enter a different number.')
                } else if (res?.statusCode == 404) {
                    // window.OTPlessSignin.initiate({
                    //     channel: "PHONE",
                    //     phone: value,
                    //     countryCode: "+91",
                    // }).then(response => {
                    //     // Handle OTP verification success
                    //     console.log('response', response.status)
                    //     if (response.success) {
                    //         toast.success('OTP send successfully!');
                    //         setIsPopupVisible(true)
                    //     } else {
                    //         toast.error('OTP send failed. Please try again.');
                    //     }
                    // })
                    console.log('check_mobile', res?.data, typeof res?.data)
                    setOtpV(res?.data)
                    setIsPopupVisible(true)

                } else {
                    toast.error(res?.error);
                }
            })
            .catch(err => {
                setLoading(false);
                toast.error("Something Went Wrong!");
            })





    }

    // console.log(typeof userData?.mobile)

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };
    const handleChange = (e) => {
        let inputValue = e.target.value;

        // Remove leading zeros
        if (inputValue.startsWith('0')) {
            inputValue = inputValue.replace(/^0+/, '');
        }

        // If the input becomes empty, set it to '0' to prevent empty input in a number field
        if (inputValue === '') {
            inputValue = '0';
        }

        setValue(inputValue);
        if (inputValue.length === 10) {
            setVerifyBtn(true);
        } else {
            setVerifyBtn(false);
        }
    };

    const [utmParams, setUtmParams] = useState({
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_term: '',
        utm_content: '',
    });

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        setUtmParams({
            utm_source: params.get('utm_source') || '',
            utm_medium: params.get('utm_medium') || '',
            utm_campaign: params.get('utm_campaign') || '',
            utm_term: params.get('utm_term') || '',
            utm_content: params.get('utm_content') || '',
        });
    }, []);

    const handleValidSubmit = (e, v) => {
        setLoading(true);
        post("register", v)
            .then(res => {
                setLoading(false);
                if (res?.statusCode == 200) {
                    setStepForm(true);
                    // localStorage.removeItem('verifiedPhoneNumber');
                    setUser(res?.data);
                    localStorage.setItem('user', JSON.stringify(res?.data))
                    setSubmitted(false);
                    navigate('/home')
                } else {
                    toast.error(res?.error);
                }
            })
            .catch(err => {
                setLoading(false);
                toast.error("Something Went Wrong!");
            })
    }



    return (
        <>

            {/* <div id="otpless-login-page"></div> */}

            <div className='main-section'>



                <Popup
                    show={isPopupVisible}
                    onClose={handleClosePopup}
                    title="Customizable Popup"
                    width="500px"
                    height="300px"
                    mobileNumber={value}
                    setOtp={setOtp}
                    handleOpenPopup={handleOpenPopup}
                    setIsVerify={setIsVerify}
                    otpV={otpV}

                />


                <div className='banner-content'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='logo-start'>
                                <div className='logo-box'>
                                    <img src={require('../assets/img/logo_1.png')} />
                                </div>
                            </div>
                            <div className='banner-left-section'>
                                <h1>
                                    {/* <span>Score your sweetness:
                                    Check your sugar intake score.</span> */}
                                    This World<br /> Diabetes Day<br />
                                    <span> #Takecontrolwithsugarfree</span>

                                </h1>
                                {/* <div className='banner-footer-section'>
                                <div className='div_setion'>
                                    <div className='content_box'>
                                        <img src={require('../assets/img/heart.png')} alt='heart' />
                                        <div className='heading_content'>
                                            <h6>know your health chart<br />
                                                for a roadmap to wellness.
                                            </h6>
                                            <Link className='Tandc'>*T&C Apply</Link>
                                        </div>
                                    </div>

                                  
                                </div>

                            </div> */}

                            </div>

                        </div>
                        <div className='col-lg-6'>
                            <div class="notify-cmpt notify-form-type-2 margin-top-xs-20 step_1_form">
                                <div class="notify-wrap contact-form-home">
                                    <h3><img src={require('../assets/img/heart.png')} alt='heart' /> <span>Know your health chart
                                        for a roadmap to wellness.</span></h3>
                                    <AvForm class="contact-form "
                                        id="otp-container"
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v)
                                        }}
                                    >
                                        <div className='row'>
                                            <div className='col-md-6 field_input'>
                                                <AvField
                                                    name="name"
                                                    type="text"
                                                    placeholder="Name"
                                                    validate={{ required: { value: true, errorMessage: 'Enter name' } }}
                                                />
                                            </div>
                                            <div className='col-md-6 field_input'>
                                                <AvField
                                                    name="email"
                                                    placeholder="Email"
                                                    type="email"
                                                    validate={{ required: { value: true, errorMessage: 'Enter email' } }}
                                                />
                                            </div>
                                            <div className='col-md-7 field_input mobile_field_input mobile_field_input_otp'>
                                                <span className='start_number'>+91</span>
                                                <AvField
                                                    name="mobile"
                                                    placeholder="Mobile"
                                                    type="text"
                                                    validate={{
                                                        required: { value: true, errorMessage: "Enter mobile" },
                                                        pattern: { value: "^[0-9]+$", errorMessage: "Invalid mobile number" },
                                                        minLength: { value: 10, errorMessage: "Must be 10 digits" },
                                                        maxLength: { value: 10, errorMessage: "Must be 10 digits" },
                                                    }}
                                                    value={value}
                                                    onChange={handleChange}

                                                />
                                                {
                                                    verifyBtn ?
                                                        <div >
                                                            <button onClick={handleOpenPopup} type='button'>Verify</button>     </div> : null

                                                }
                                            </div>
                                            {/* <div className='col-md-6 field_input'>
                                            <AvField
                                                name="age"
                                                placeholder="Age"
                                                type="number"
                                                validate={{ required: { value: true, errorMessage: 'Enter age' } }}
                                            />
                                        </div> */}

                                            <div className='col-md-5 field_input'>
                                                <AvField
                                                    type="select"
                                                    name="gender"
                                                    validate={{ required: { value: true, errorMessage: 'Select gender' } }}
                                                >
                                                    <option value="">Select Gender</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Perefer Not To Say">Perefer Not To Say</option>
                                                </AvField>
                                            </div>
                                            <div className='col-md-12 field_input'>
                                                <AvField
                                                    type="select"
                                                    name="ageGroup"
                                                    validate={{ required: { value: true, errorMessage: 'Select age group' } }}
                                                >
                                                    <option value="">Select Age Group</option>
                                                    <option value={"18-30"}>18-30</option>
                                                    <option value={"31-45"}>31-45</option>
                                                    <option value={"46-60"}>46-60</option>
                                                    <option value={"60 above"}>60 above</option>
                                                </AvField>
                                            </div>
                                        </div>
                                        {/* <div className='chkbox'> */}
                                        <AvCheckboxGroup name="checkboxExample" className='chkbox' label="">
                                            <AvCheckbox
                                                label="I agree to be contacted by Zydus Wellness via WhatsApp, SMS, Phone, Email etc."
                                                errorMessage="You need to agree"
                                                value="agree" checked />
                                        </AvCheckboxGroup>
                                        {/* </div> */}
                                        <AvField type="hidden" name="utm_source" id="source_b" value={utmParams.utm_source} />
                                        <AvField type="hidden" name="utm_medium" id="medium_b" value={utmParams.utm_medium} />
                                        <AvField type="hidden" name="utm_campaign" id="campaign_b" value={utmParams.utm_campaign} />
                                        <AvField type="hidden" name="utm_term" id="term_b" value={utmParams.utm_term} />
                                        <AvField type="hidden" name="utm_term" id="content_b" value={utmParams.utm_term} />

                                        <div className='next_btn_box'>
                                            <button type="submit" class="btn btn-default progress-button btn-submit" disabled={!isVerify}>Next
                                                <span class="mask"></span>
                                            </button>
                                        </div>

                                    </AvForm>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>

                            <div class="container-fluid pad-zero social-wrpper ">

                                {/* <!--Footer--> */}
                                <footer class="copyright-text margin-sm-30 margin-top-xs-0">
                                    {/* <!--Social Icons--> */}
                                    <ul class="social-icons animated social-wrpper-home">
                                        <li>
                                            <a href="https://www.facebook.com/SugarfreeIndia/" target='_blank' class="social-icon">
                                                <span class="fa" data-hover="&#xf09a;">&#xf09a;</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://x.com/i/flow/login?redirect_after_login=%2Fsugarfreeindia" target='_blank' class="social-icon">
                                                <span class="fa" data-hover="&#xf099;">&#xf099;</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UCpUmPO6BUHEawp3VLggNTAw" target='_blank' class="social-icon">
                                                <span class="fa" data-hover="&#xf167;">&#xf167;</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/sugarfree.india/" target='_blank' class="social-icon">
                                                <span class="fa" data-hover="&#xf16d;">&#xf16d;</span>
                                            </a>
                                        </li>
                                    </ul>
                                    {/* <!--/Social Icons--> */}

                                    <div className='footer_copyright footer_copyright_home'>

                                        <ul className='footer_link'>
                                            <li>Copyright © Sugar Free.</li>
                                            <li><a href='https://www.sugarfree-india.com/contest-and-promotions' target='_blank'>Terms</a></li>
                                            <li>|</li>
                                            <li><a href='https://www.zyduswellness.com/privacy-policy.php' target='_blank'>Privacy Policy</a></li>
                                        </ul>
                                    </div>
                                </footer>
                                {/* <!--/Footer--> */}

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

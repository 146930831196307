import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';


const Popup = ({ show, onClose, otpV, handleOpenPopup, title, children, mobileNumber, setIsVerify }) => {


    const [otp, setOtp] = useState(Array(4).fill(''));
    const [timeLeft, setTimeLeft] = useState(60); // 2 minutes in seconds
    const [canResend, setCanResend] = useState(true);
    const timerRef = useRef(null);
    const inputRefs = useRef([]);

    useEffect(() => {
        if (show) {
            startTimer();
        } else {
            clearInterval(timerRef.current);
            setTimeLeft(60); // Reset timer when popup closes
            setOtp(Array(4).fill('')); // Optionally reset OTP input as well
            setCanResend(true);
        }

        return () => clearInterval(timerRef.current);
    }, [show]);

    useEffect(() => {
        if (timeLeft === 0) {
            setCanResend(false);
            clearInterval(timerRef.current);
        }
    }, [timeLeft]);

    // useEffect(() => {
    //     // Close popup when clicking outside of it
    //     const handleClickOutside = (event) => {
    //         if (show && event.target.className === 'popup-overlay') {
    //             onClose();
    //         }
    //     };

    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => document.removeEventListener('mousedown', handleClickOutside);
    // }, [show, onClose]);

    const startTimer = () => {
        timerRef.current = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev <= 1) {
                    clearInterval(timerRef.current);
                    setCanResend(false);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    const resendOTP = () => {
        if (canResend) {
            toast.error("Cannot resend code. Time has expired.");
            return;
        }
        // window.OTPlessSignin.initiate({
        //     channel: "PHONE",
        //     phone: mobileNumber,
        //     countryCode: "+91",
        // }).then(response => {
        //     // Handle OTP verification success
        //     console.log('response', response)
        //     if (response.success) {
        //         toast.success('OTP send successfully!');
        //     } else {
        //         toast.error('OTP send failed. Please try again.');
        //     }
        // })
        handleOpenPopup()
        // toast.success("New OTP sent!")
        setOtp(Array(4).fill(''));
        setTimeLeft(60);
        setCanResend(true); // Allow resending OTP again
        clearInterval(timerRef.current);
        startTimer();
        inputRefs.current[0].focus();
    };
    // console.log('canResend',canResend)

    const handleInputChange = (value, index) => {
        if (/^\d?$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);


            if (value && index < otp.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData('text').slice(0, otp.length); // Limit to OTP length
        const newOtp = otp.map((_, i) => pasteData[i] || '');
        setOtp(newOtp);
    
        // Move focus to the next empty input or the last input
        for (let i = 0; i < newOtp.length; i++) {
            if (newOtp[i] === '') {
                inputRefs.current[i].focus();
                break;
            } else if (i === newOtp.length - 1) {
                inputRefs.current[i].focus();
            }
        }
    
        e.preventDefault();
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const verifyOTP = () => {
        // Ensure that OTPlessSignin is available before calling initiate
        // if (!window.OTPlessSignin) {
        //     console.error("OTPlessSignin is not initialized");
        //     return;
        // }

        const otpValue = otp.join('');
        if (otpV === otpValue) {
            if (timeLeft > 0) {
                // window.OTPlessSignin.verify({
                //     channel: "PHONE",
                //     phone: mobileNumber,
                //     countryCode: "+91",
                //     otp: otpValue,
                // }).then(response => {
                //     // Handle OTP verification success
                //     console.log('response', response)
                //     if (response.success) {
                //         toast.success('OTP verified successfully!');
                //         setIsVerify(true)
                //         localStorage.setItem('verifiedPhoneNumber', mobileNumber);
                //         onClose()
                //     } else {
                //         toast.error('OTP verification failed. Please try again.');
                //     }
                // })
                localStorage.setItem('verifiedPhoneNumber', mobileNumber);
                setIsVerify(true)
                toast.success('OTP verified successfully!');
                onClose()
            } else {
                toast.error('OTP has expired. Please request a new one.');
            }
        } else {
            toast.error('Please enter a 4-digit OTP');
        }
    };
    const handleOnInput = (e, index) => {
        const value = e.target.value; // Get the value from the input event

        const newOtp = [...otp];
        newOtp[index] = value.slice(-1); // Ensures only the last character is taken for single digit
        setOtp(newOtp);

        // Move to the next input if available and the input is not empty
        if (value && index < otp.length - 1) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    console.log(otpV)
    if (!show) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <div className="popup-body">
                    <img src={require('../assets/img/mail_8819054.gif')} />
                    <p>Enter The Verification Code Sent To {mobileNumber}</p>
                    <div className="otp-verification">
                        <div className="otp-input" onPaste={handlePaste} autoFocus  >
                            {otp.map((_, index) => (
                                <input
                                    key={index}
                                    type="number"
                                    min="0"
                                    max="9"
                                    required
                                    value={otp[index]}
                                    onChange={(e) => handleInputChange(e.target.value, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                   
                                    
                                />
                            ))}
                        </div>
                        <div className="resend-text">
                            Didn't receive the code?{' '}
                            {
                                timeLeft === 0 ? <span className="resend-link" onClick={() => { resendOTP(); }}>Resend Code</span> : null
                            }
                            <span id="timer" className={timeLeft === 0 ? 'expired' : ''}>
                                {timeLeft > 0 ? `(${minutes}:${seconds.toString().padStart(2, '0')})` : 'Code expired'}
                            </span>
                        </div>
                        <div className='modal_btn_g'>
                            <button type="button" className='close_btn' onClick={onClose}>Close</button>
                            <button type="button" className='okButton' onClick={verifyOTP}>Verify</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Popup;
